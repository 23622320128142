import React, {Component} from "react";

class NoResult extends Component {
    constructor(props) {
        super(props);
        this.state = {loadingText: 'Loading...'}
    }
    componentDidMount() {
        setTimeout(() => {
            if (document.getElementById('loader') !== null) {
                this.setState({loadingText: "No Resources Found"})
            }
        }, 2000)
    }

    render() {
        return (
            <div className="col-sm-4 offset-4">
                <h1 id="loader">
                    {this.state.loadingText}
                </h1>
            </div>
        )
    }
}

export default NoResult;