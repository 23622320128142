import React, {Component} from 'react';
import NoResult from "./NoResult";
import TypeIcon from "./TypeIcon";
import {withCookies} from 'react-cookie';

//import LinkRow from './LinkRow'
class App extends Component {

    constructor(props) {
        super(props);
        const {cookies} = props;
        this.state = {
            links: [],
            pageViewCount: cookies.get('pageViewCount') || 0
        };
    }

    componentDidMount() {
        const {cookies} = this.props;
        let updatedCount = parseInt(this.state.pageViewCount) + 1;
        cookies.set('pageViewCount', updatedCount, {path: '/'});
        let qs = '&filterByFormula='
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('cat') && urlParams.has('subCat')) {
            qs += `AND({Category}='${urlParams.get('cat')}',FIND('${urlParams.get('subCat')}',{Subcategory}))`
        }
        fetch('https://api.airtable.com/v0/appdoSubLbBl0SZOg/Imported%20table?api_key=keyDB970V4kWQFUWh' + qs)
            .then((resp) => resp.json())
            .then(data => {
                this.setState({links: data.records, pageViewCount: updatedCount})
            }).catch(err => {
            console.log(err)
        });
    }

    render() {
        if (this.state.pageViewCount % 4 === 0){
            document.getElementById('button').click()
        }
        return (
            <div className="container mt-5">
                <div className="row">
                    {this.state.links.length > 0 ? this.state.links.map(link => <LinkCard {...link.fields}/>) :
                        <NoResult/>}
                </div>
            </div>
        );
    }
}

export default withCookies(App);

const LinkCard = ({Link, Publisher, Title, Author, Year, Type}) => (
    <div className="col-md-4 py-3">
        <div className="card shadow-sm h-100">
            <div className="card-body h-100">
                <h5 className="card-title">{Title}</h5>
                <div className="align-self-end">
                    <p className="card-text">
                        <small className="text-muted">Publisher: {Publisher}</small>
                    </p>
                    <p className="card-text">
                        <small className="text-muted">Author: {Author}</small>
                    </p>
                    <p className="card-text">
                        <small className="text-muted">Year: {Year}</small>
                    </p>
                    <p className="card-text">
                        <small className="text-muted">Type: <TypeIcon type={Type}/> {Type}</small>
                    </p>
                </div>
            </div>
            <div className="card-footer">
                <a className="align-self-end btn btn-lg btn-block btn-success align-bottom" href={Link}>Go to
                    resource</a>
            </div>
        </div>
    </div>
);